import axios from "axios"
import apiConfig from '../config/api.config';

export async function getCreditExchangeRate() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_CREDIT_EXCHANGE_RATE)
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function getResellerCreditPurchaseTransactions(resellerId, page, requestorEmail, dateRangeObj = {}) {
    const params = {
        resellerId: resellerId,
        requestorEmail: requestorEmail,
        page: page,
        access_token: localStorage.getItem("access_token"),
        dateRange: dateRangeObj
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_PAGED_RESELLER_RAZORPAY_PAYMENT, { params })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

// Razorpay

export async function getRazorpayKey() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_RAZORPAY_KEY)
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function createRazorpayOrder(amount, resellerId) {
    const payload = { amount: amount, requestorEmail: resellerId, access_token: localStorage.getItem("access_token") };
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.RAZORPAY, payload)
            .then(response => {
                if (response.status === 200 && response.data.success)
                    resolve(response.data.result);
                else
                    reject(response.data.errMsg);
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

// Bkash

export async function checkBkashPaymentStatus(spTransID, requestorEmail) {
    let params = {
        requestorEmail: requestorEmail,
        spTransID: spTransID,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.CHECK_BKASH_PAYMENT_STATUS, { params })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function getBKashPaymentStatus(requestorEmail, spTransID) {
    let payload = {
        requestorEmail: requestorEmail,
        spTransID: spTransID,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.BKASH_PAYMENT_VERIFICATION, payload)
            .then(response => {
                if (!response || !response.data) return reject("Error getting response from payment server. Please reach out to our support if money is deducted and credits not updated");
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function sendBkashResolve(spTransID, amount, mfCredits, status, resellerEmailId, requestorEmail) {
    let payload = {
        spTransID: spTransID,
        amount: amount,
        mfCredits: mfCredits,
        resellerEmailId: resellerEmailId,
        status: status,
        requestorEmail: requestorEmail,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.REFUND_BKASH_PAYMENT, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(true);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function createBKashOrder(requestorEmail, amount) {
    let payload = {
        requestorEmail: requestorEmail,
        amount: amount,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_BKASH_ORDER, payload)
            .then(response => {
                if (!response || !response.data) return reject("Error initiating the payment. Please try again!");
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

function rejectWithErrorMsg(reject, error) {
    if (error && error.response && error.response.data && error.response.data.errMsg) reject(error.response.data.errMsg)
    else reject("Error in connection");
}

export async function createAamarpayOrder(requestorEmail, amount, mfValue) {
    let payload = {
        requestorEmail: requestorEmail,
        amount: amount,
        mfValue: mfValue,
        access_token: localStorage.getItem("access_token"),
        url: window.location.href
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_AAMARPAY_ORDER, payload)
            .then(response => {
                if (!response || !response.data) return reject("Error initiating the payment. Please try again!");
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function checkGiftCardBalance(cardNumber) {
    let payload = {
        giftCardId : cardNumber
    };

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GIFTCARD_VALIDATE, payload)
            .then(response => {
                if (!response) return reject("Error in validating the gift card. Please try again !");
                if(response.data.result){
                    resolve(response.data.result);
                }
                else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
            console.error(err);
            rejectWithErrorMsg(reject, err);
        });
    });
}

export async function redeemGiftCard(cardNumber, dueAmount, cardPin, cardBalance, playerId, packageName, emailId, selectedGame, mfCredits) {
    let payload = {
        giftCardId : cardNumber,
        dueAmount: dueAmount,
        mfCredits: mfCredits,
        cardPin: cardPin,
        cardBalance: cardBalance,
        playerId: playerId,
        packageName: packageName,
        emailId: emailId,
        selectedGame: selectedGame
    };

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GIFTCARD_REDEEM, payload)
            .then( response => {
                if(!response) return reject("Error in redeeming the gift card. Please try again !");
                if(response.data.result) {
                    resolve(response.data.result);
                }
                else {
                    reject(response.data.errMsg);
                }
            }).catch((err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
        }));
    });
}