<template>
  <div class="container">
    <!-- Header -->
    <div class="row py-5">
      <div class="col-lg-9 mx-auto text-white text-center">
        <h1 class="display-4" style="font-size: 5.2rem !important">
          Moonfrog Sage Ops
        </h1>
        <!-- <p class="lead mb-0">Onboard Resellers and Approve Payments.</p> -->
        <p class="lead">
          Product by
          <a href="https://moonfroglabs.com/" class="text-white">
            <u>Moonfrog Labs</u>
          </a>
        </p>
        <button id="sign-out-btn" class="text-white lead" @click="signOut">
          Sign Out
        </button>
      </div>
    </div>
    <!-- Search -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          Search for Reseller to Activate or Deactivate
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <form action="" id="mainForm">
            <div class="row">
              <!-- Reseller Email Search Field -->
              <div
                class="col-lg-12 bg-white rounded rounded-pill shadow-sm mb-4"
              >
                <div class="input-group">
                  <input
                    v-model="resellerId"
                    type="search"
                    placeholder="Enter Reseller Gmail ID"
                    aria-describedby="button-addon1"
                    class="form-control border-0 bg-white"
                  />
                  <div class="input-group-append">
                    <button
                      id="button-addon1"
                      type="submit"
                      @click="fetchReseller($event)"
                      class="btn btn-link text-primary"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- Reseller Name Field -->
              <div class="bg-white mb-4 col-lg-6" v-if="isReseller">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="
                        input-group-text
                        bg-white
                        px-4
                        border-md border-right-0
                      "
                    >
                      <i class="fa fa-user text-muted"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="name"
                    aria-describedby="button-addon1"
                    class="form-control border-md bg-white border-left-0"
                    readonly
                  />
                </div>
              </div>
              <!-- Reseller Phone Number Field -->
              <div class="bg-white mb-4 col-lg-6" v-if="isReseller">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="
                        input-group-text
                        bg-white
                        px-4
                        border-md border-right-0
                      "
                    >
                      <i class="fa fa-phone-square text-muted"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="phoneNumber"
                    aria-describedby="button-addon1"
                    class="form-control border-md bg-white border-left-0"
                    readonly
                  />
                </div>
              </div>
              <!-- Parent Reseller ID -->
              <div
                class="bg-white mb-4 col-lg-6"
                v-if="isReseller && subresellerParentResellerId.length > 0"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="
                        input-group-text
                        bg-white
                        px-4
                        border-md border-right-0
                      "
                    >
                      <i class="fa fa-group text-muted"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="subresellerParentResellerId"
                    aria-describedby="button-addon1"
                    class="form-control border-md bg-white border-left-0"
                    readonly
                  />
                </div>
              </div>
              <!-- Wallet Balance -->
              <div
                class="bg-white mb-4 col-lg-6"
                v-if="resellerStatus === 'ACTIVE'"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="
                        input-group-text
                        bg-white
                        px-4
                        border-md border-right-0
                      "
                    >
                      <i class="fa fa-money text-muted"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="walletBalance"
                    aria-describedby="button-addon1"
                    class="form-control border-md bg-white border-left-0"
                    readonly
                  />
                </div>
              </div>
              <!-- Add MF Credits -->
              <div
                class="col-lg-6 bg-white mb-4"
                v-if="resellerStatus === 'ACTIVE'"
              >
                <div class="input-group">
                  <input
                    placeholder="Add MF Credits"
                    v-model="addMFCredits"
                    type="search"
                    aria-describedby="button-addon1"
                    class="form-control border-md border-right-0 bg-white"
                  />
                  <div class="input-group-append">
                    <button
                      id="button-addon2"
                      type="submit"
                      @click="updateWallet"
                      class="btn btn-link text-primary add-mf-credit-btn"
                    >
                      <i class="fa fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- Country Code -->
              <div
                class="bg-white mb-4 col-lg-6"
                v-if="resellerStatus === 'ACTIVE'"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="
                        input-group-text
                        bg-white
                        px-4
                        border-md border-right-0
                      "
                    >
                      <i class="fa fa-flag text-muted"></i>
                    </span>
                  </div>
                  <input
                    :placeholder="resellerCountryCode"
                    aria-describedby="button-addon1"
                    class="form-control border-md bg-white border-left-0"
                    readonly
                  />
                </div>
              </div>
              <!-- Download Contract Button -->
              <div
                class="mb-4 col-lg-3"
                style="margin-left: auto; margin-right: auto"
                v-if="resellerStatus === 'ACTIVE'"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="onKycButtonClicked"
                >
                  <i
                    class="fa fa-file-image-o icon"
                    aria-hidden="true"
                    style="color: white"
                  ></i>
                  {{ kycButtonText }}
                </button>
              </div>
              <!-- Deactivate Button -->
              <div
                class="mb-4 col-lg-3"
                style="margin-left: auto; margin-right: auto"
                v-if="resellerStatus === 'ACTIVE'"
              >
                <button
                  id="deactivate-btn"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="deactivate"
                >
                  Deactivate
                </button>
              </div>
              <!-- Activate Button -->
              <div
                class="mb-4 col-lg-3"
                style="margin-left: auto; margin-right: auto"
                v-if="resellerStatus === 'DEACTIVATED'"
              >
                <button
                  id="activate-btn"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="activate"
                >
                  Activate
                </button>
              </div>
              <!-- KYC Button -->
              <!-- <div class="mb-4 col-lg-6" v-if="resellerStatus === 'PENDING'">
                <button
                  class="btn btn-lg btn-block"
                  type="button"
                  style="border-color: black"
                  @click="showKYC"
                >
                  <i class="fa fa-file-image-o icon" aria-hidden="true"></i>
                  View KYC Document
                </button>
              </div> -->
              <div
                class="mb-4 col-lg-6"
                v-if="
                  resellerStatus === 'PENDING' &&
                  subresellerParentResellerId.length === 0
                "
              ></div>
              <!-- Proceed Button -->
              <div
                class="mb-4 col-lg-3"
                style="margin-left: auto"
                v-if="resellerStatus === 'PENDING'"
              >
                <button
                  id="proceed-btn"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="proceed"
                >
                  Proceed
                </button>
              </div>
              <!-- Reject Button -->
              <div
                class="mb-4 col-lg-3"
                style="margin-right: auto"
                v-if="resellerStatus === 'PENDING'"
              >
                <button
                  id="deactivate-btn"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="reject"
                >
                  Reject
                </button>
              </div>
              <!-- Date of Birth -->
              <div class="input-group col-lg-6 mb-4" v-if="isProceed === true">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-white
                      px-4
                      border-md border-right-0
                    "
                  >
                    <i class="fa fa-birthday-cake text-muted"></i>
                  </span>
                </div>
                <input
                  v-model="dateOfBirth"
                  type="text"
                  placeholder="Date of Birth"
                  class="form-control bg-white border-left-0 border-md"
                  id="dateOfBirth"
                  onfocus="(this.type='date')"
                />
              </div>
              <!-- Gender -->
              <div class="input-group col-lg-6 mb-4" v-if="isProceed === true">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-white
                      px-4
                      border-md border-right-0
                    "
                  >
                    <i class="fa fa-venus-mars text-muted"></i>
                  </span>
                </div>
                <select
                  v-model="gender"
                  id="gender"
                  name="gender"
                  class="
                    form-control
                    custom-select
                    bg-white
                    border-left-0 border-md
                  "
                >
                  <option value="">{{ genderOptions[0] }}</option>
                  <option value="Male">{{ genderOptions[1] }}</option>
                  <option value="Female">{{ genderOptions[2] }}</option>
                  <option value="Prefer not to say">
                    {{ genderOptions[3] }}
                  </option>
                </select>
              </div>

              <!-- Country code -->
              <div class="input-group col-lg-6 mb-4" v-if="isProceed === true">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-white
                      px-4
                      border-md border-right-0
                    "
                  >
                    <i class="fa fa-flag text-muted"></i>
                  </span>
                </div>
                <select
                  v-model="countryCode"
                  id="countryCode"
                  name="countryCode"
                  class="
                    form-control
                    custom-select
                    bg-white
                    border-left-0 border-md
                  "
                >
                  <option value="">Select the Country Code</option>
                  <option
                    v-for="countryCode in countryCodes"
                    :key="countryCode"
                    :value="countryCode"
                  >
                    {{ countryCode }}
                  </option>
                </select>
              </div>

              <!-- Onboard Button -->
              <div
                class="mb-4 col-lg-3"
                style="margin-left: auto; margin-right: auto; height: 1.5rem"
                v-if="isProceed === true"
              >
                <button
                  id="onboard-btn"
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="onboard"
                >
                  Activate
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Reseller List -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          Details of registration requests and status
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <v-card>
            <v-card-title>
              Reseller Registration Requests
              <v-spacer></v-spacer>
              <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field> -->
            </v-card-title>
            <!-- <v-data-table
              :headers="headers"
              :items="resellerArr"
              :search="search"
            > -->
            <v-data-table
              :headers="headers"
              :items="resellerArr"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              :loading="!resellersLoaded"
            >
              <!-- eslint-disable -->
              <!-- get readable timestamp -->
              <template v-slot:item.createDate="{ item }">
                {{ getTimeStamp(item.createDate) }}
              </template>
              <!-- color status chip -->
              <template v-slot:item.status="{ item }">
                <v-tooltip left v-if="item.status !== 'PENDING'">
                  <template v-slot:activator="{ on }">
                    <v-chip
                      :color="getResellerColor(item.status)"
                      dark
                      v-on="on"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                  <span>{{ item.mfOpsIdActDeactReject }}</span>
                </v-tooltip>
                <v-chip v-else :color="getResellerColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
              <!-- get parent reseller on hover -->
              <template v-slot:item.userType="{ item }">
                <v-tooltip
                  left
                  v-if="item.userType.indexOf('SUBRESELLER') > -1"
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.userType }}</div>
                  </template>
                  <span> {{ item.parentResellerId }} </span>
                </v-tooltip>
                <div v-else>{{ item.userType }}</div>
              </template>
            </v-data-table>
            <v-pagination
              v-model="paginationParamsResellers.page"
              :length="paginationParamsResellers.totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChangeResellers"
            ></v-pagination>
          </v-card>
        </div>
      </div>
    </div>
    <!-- Manual Payments List -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          Details of bank payment requests received
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <v-card>
            <!-- Table Header (Search + Title) -->
            <v-card-title>
              Bank Payment Transactions
              <v-spacer></v-spacer>
              <!-- <v-text-field
                v-model="search1"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field> -->
            </v-card-title>
            <!-- Search Between Dates -->
            <!-- <v-row style="display: grid">
              <v-col
                cols="12"
                sm="6"
                style="justify-self: end; margin-inline-end: 16px"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Select Date Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearPicker">
                      Clear
                    </v-btn>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row> -->
            <!-- Payments Table -->
            <!-- <v-data-table
              :headers="headers2"
              :items="paymentsArr"
              :search="search1"
              class="elevation-1"
            > -->
            <v-data-table
              :headers="headers2"
              :items="paymentsArr"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              :loading="!paymentsLoaded"
            >
              <template v-slot:item.timestamp="{ item }">
                {{ getTimeStamp(item.timestamp) }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getPaymentColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:item.controls="{ item }">
                <v-icon small class="mr-2" @click="approvePayment(item)">
                  mdi-check
                </v-icon>
                <v-icon small @click="rejectPayment(item)"> mdi-close </v-icon>
              </template>
            </v-data-table>
            <v-pagination
              v-model="paginationParamsPayments.page"
              :length="paginationParamsPayments.totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChangePayments"
            ></v-pagination>
          </v-card>
        </div>
      </div>
    </div>
    <!-- BKash Payment Search Field -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          Search for spTransID to find a BKASH Payment
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <div class="row">
            <div class="col-lg-12 bg-white rounded rounded-pill shadow-sm mb-4">
              <div class="input-group">
                <input
                  v-model="spTransID"
                  type="search"
                  placeholder="BKASH spTransID"
                  aria-describedby="button-addon1"
                  class="form-control border-0 bg-white"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    @click="fetchBkashPayment($event)"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Razorpay Payments List -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          Details of payments made via Razorpay / BKASH
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <v-card>
            <!-- Table Header (Search + Title) -->
            <v-card-title>
              <div style="padding: 10px" class="row">
                MFCredit Purchases
                <v-spacer></v-spacer
                ><v-menu
                  v-model="paymentMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="paymentsDateRangeText"
                      label="Date Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="paymentDates"
                    scrollable
                    no-title
                    @change="onPaymentDateSelected()"
                    range
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn
                  class="
                    mx-1
                    subtitle-1
                    white--text
                    font-weight-regular
                    text-capitalize
                  "
                  color="teal darken-4"
                  medium
                  @click="searchPaymentTxns()"
                  ><v-icon dark> fa fa-search</v-icon></v-btn
                >
                <v-btn
                  class="
                    mx-1
                    subtitle-1
                    white--text
                    font-weight-regular
                    text-capitalize
                  "
                  :disabled="razorpayPaymentsArr.length === 0"
                  @click="downloadPaymentTxns()"
                  color="light-blue darken-4"
                  ><v-icon dark> mdi-cloud-download</v-icon></v-btn
                >
                <!-- <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field> -->
              </div>
            </v-card-title>
            <v-data-table
              :headers="headers3"
              :items="razorpayPaymentsArr"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              :loading="!razorpayPaymentsLoaded"
            >
              <template v-slot:item.timestamp="{ item }">
                {{ getTimeStamp(item.timestamp) }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="getRZPPaymentColor(item.status)" dark>
                  {{ getRZPStatusCode(item.status) }}
                </v-chip>
              </template>
            </v-data-table>
            <v-pagination
              v-model="paginationParamsRazorpayPayments.page"
              :length="paginationParamsRazorpayPayments.totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChangeRazorpayPayments"
            ></v-pagination>
          </v-card>
        </div>
      </div>
    </div>
    <!-- MFOps Grant Payments List -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          MFOps Grant List
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <v-card>
            <!-- Table Header (Search + Title) -->
            <v-card-title>
              <div style="padding: 10px" class="row">
                MFCredit Grants
                <v-spacer></v-spacer
                ><v-menu
                  v-model="grantMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="grantsDateRangeText"
                      label="Date Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="grantDates"
                    scrollable
                    no-title
                    @change="onGrantDateSelected()"
                    range
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn
                  class="
                    mx-1
                    subtitle-1
                    white--text
                    font-weight-regular
                    text-capitalize
                  "
                  color="teal darken-4"
                  medium
                  @click="searchGrantTxns()"
                  ><v-icon dark> fa fa-search</v-icon></v-btn
                >
                <v-btn
                  class="
                    mx-1
                    subtitle-1
                    white--text
                    font-weight-regular
                    text-capitalize
                  "
                  @click="downloadGrantTxns()"
                  :disabled="mfopsGrantsArr.length === 0"
                  color="light-blue darken-4"
                  ><v-icon dark> mdi-cloud-download</v-icon></v-btn
                >
                <!-- <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field> -->
              </div>
            </v-card-title>
            <v-data-table
              :headers="headers4"
              :items="mfopsGrantsArr"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              :loading="!grantsLoaded"
            >
              <!-- Get readable timestamp -->
              <template v-slot:item.timestamp="{ item }">
                {{ getTimeStamp(item.timestamp) }}
              </template>
              <!-- color status chip -->
              <template v-slot:item.status="{ item }">
                <v-chip :color="getPaymentColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
              <!-- get sender type on hover -->
              <template v-slot:item.sender="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.sender }}</div>
                  </template>
                  <span>{{ item.senderType }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-pagination
              v-model="paginationParamsMfopsGrants.page"
              :length="paginationParamsMfopsGrants.totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChangeMfopsGrants"
            ></v-pagination>
          </v-card>
        </div>
      </div>
    </div>
    <!-- Player Recharges -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          Player Recharges Table
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <ResellerPlayerTransactions
            :isLoading="!playerRechargesLoaded"
            :transactions="playerRechargesArr"
            @pageChanged="handlePageChangePlayerRecharges"
            :page="paginationParamsPlayerRecharges.page"
            :totalPages="paginationParamsPlayerRecharges.totalPages"
            title="Player Recharges"
            @download="downloadRechargeTxns"
            @search="searchRechargeTxns"
            :isMfopsRequestor="true"
          />
        </div>
      </div>
    </div>

    <!-- Player Network List -->
    <div class="row mb-5">
      <div class="col-lg-8 mx-auto">
        <h5 class="font-weight-light mb-4 font-italic text-white">
          List of all successfull referrals by resellers
        </h5>
        <div class="bg-white p-5 rounded shadow">
          <PlayerNetworkTable
            :isLoading="!playerNetworkLoaded"
            :players="playerNetworkArr"
            @pageChanged="handlePageChangePlayerNetwork"
            :page="paginationParamsPlayerNetwork.page"
            :totalPages="paginationParamsPlayerNetwork.totalPages"
            title="Referred Player Network of Resellers"
            @search="searchPlayerNetwork"
            @download="downloadPlayerNetwork"
            :isMfopsRequestor="true"
          />
        </div>
      </div>
    </div>

    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<!-- eslint-enable -->

<script>
import * as client from "@/client/webclient";
import * as PaymentClient from "@/client/PaymentClient";
import * as SageConstants from "../config/SageConstants";
import * as FileType from "file-type/browser";
import * as SageUtils from "../utils/SageUtils";
import ResellerPlayerTransactions from "@/components/ResellerPlayerTransactions";
import PlayerNetworkTable from "@/components/PlayerNetworkTable";

export default {
  name: "MfOps",
  components: {
    ResellerPlayerTransactions,
    PlayerNetworkTable,
  },

  created() {
    this.init();
    SageConstants.countryCodes.forEach((code) => {
      this.countryCodes.push(code);
    });
  },

  data() {
    return {
      resellerId: "",
      resellerStatus: "",
      dateOfBirth: "",
      gender: "",
      name: "Name",
      phoneNumber: "Phone Number",
      walletBalance: 0,
      addMFCredits: "",
      resellerCountryCode: "",
      // KYCimage: "",
      subresellerParentResellerId: "",

      isProceed: false,
      isReseller: false,
      genderOptions: ["Choose Gender", "Male", "Female", "Prefer not to say"],

      paymentsLoaded: false,
      resellersLoaded: false,
      razorpayPaymentsLoaded: false,
      grantsLoaded: false,
      playerRechargesLoaded: false,
      playerNetworkLoaded: false,

      // search: "",
      // search1: "",
      headers: [
        { text: "Registration Date", align: "start", value: "createDate" },
        { text: "Name", value: "name" },
        { text: "User Type", value: "userType" },
        { text: "Email", value: "id" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Status", value: "status" },
      ],
      resellerArr: [],
      paymentsArr: [],
      razorpayPaymentsArr: [],
      mfopsGrantsArr: [],
      playerRechargesArr: [],
      playerNetworkArr: [],

      // menu: false,
      // dates: [],

      isLoading: true,

      paginationParamsPayments: {
        page: 1,
        totalPages: 0,
      },

      paginationParamsResellers: {
        page: 1,
        totalPages: 0,
      },

      paginationParamsRazorpayPayments: {
        page: 1,
        totalPages: 0,
      },

      paginationParamsMfopsGrants: {
        page: 1,
        totalPages: 0,
      },

      paginationParamsPlayerRecharges: {
        page: 1,
        totalPages: 0,
        resellerId: "",
      },

      paginationParamsPlayerNetwork: {
        page: 1,
        totalPages: 0,
      },

      countryCodes: [],
      countryCode: "",

      grantDates: [
        SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
        SageUtils.getDateFromToday(0),
      ],
      paymentDates: [
        SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
        SageUtils.getDateFromToday(0),
      ],
      paymentMenu: false,
      grantMenu: false,
      rechargesMenu: false,

      playerNetworkResellerId: "",

      kycButtonText: "",
      spTransID: "",
    };
  },

  computed: {
    grantsDateRangeText() {
      return this.grantDates.join(" ~ ");
    },
    paymentsDateRangeText() {
      return this.paymentDates.join(" ~ ");
    },
    headers2() {
      return [
        {
          text: "Date/Time",
          align: "start",
          value: "timestamp",
          // filter: (value) => {
          //   if (this.dates.length === 0) return true;

          //   let startDate = new Date(this.dates[0] + "T00:00:00Z").getTime();
          //   let endDate = new Date(this.dates[1] + "T00:00:00Z").getTime();
          //   let val = new Date(value).getTime();
          //   return val <= endDate && val >= startDate;
          // },
        },
        { text: "Email", value: "userId" },
        { text: "Amount (MF Credits)", value: "mfValue" },
        { text: "Bank Name", value: "bankName" },
        { text: "Account Number", value: "accountNo" },
        { text: "Transaction Number", value: "txnNo" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "controls", sortable: false },
      ];
    },
    headers3() {
      return [
        {
          text: "Date/Time",
          align: "start",
          value: "timestamp",
        },
        { text: "Email", value: "userId" },
        { text: "MF Credits", value: "mfValue" },
        { text: "Price", value: "price" },
        { text: "Payment ID", value: "paymentId" },
        { text: "Status", value: "status" },
      ];
    },
    headers4() {
      return [
        {
          text: "Date/Time",
          align: "start",
          value: "timestamp",
        },
        { text: "Email", value: "userId" },
        { text: "MF Credits", value: "mfValue" },
        { text: "Payment ID", value: "id" },
        { text: "Sender", value: "sender" },
        { text: "Status", value: "status" },
      ];
    },
    playerNetworkHeaders() {
      return [
        {
          text: "Install Date/Time",
          align: "start",
          value: "installTimestamp",
        },
        { text: "Reseller Email", value: "resellerId" },
        { text: "Game Name", value: "gameName" },
        { text: "Player ID", value: "playerId" },
        { text: "Total Purchase", value: "amount" },
        { text: "Status", value: "status" },
      ];
    },
  },

  methods: {
    async init() {
      try {
        //Reseller Mfops Grants Payments
        client
          .grantHistory(
            this.paginationParamsMfopsGrants.page,
            this.$store.state.account.email,
            SageConstants.userTypeConstants.MFOPS,
            "empty",
            this.getDateRangeObj(this.grantDates)
          )
          .then((grantsData) => {
            this.mfopsGrantsArr = grantsData.txns;
            this.grantsLoaded = true;
            this.paginationParamsMfopsGrants.totalPages = grantsData.totalPages;
            this.mfopsGrantsArr.forEach((item) => {
              item.sender = item.resellerId ? item.resellerId : item.mfopsId;
              item.senderType = item.resellerId ? "RESELLER" : "MFOPS";
            });
          })
          .catch((e) => {
            this.showConnectionErrorDialog(e);
            this.grantsLoaded = true;
          });

        //Razorpay Payments
        PaymentClient.getResellerCreditPurchaseTransactions(
          "",
          this.paginationParamsPayments.page,
          this.$store.state.account.email,
          this.getDateRangeObj(this.paymentDates)
        )
          .then((razorpayPaymentsData) => {
            this.razorpayPaymentsArr = razorpayPaymentsData.txns;
            this.razorpayPaymentsLoaded = true;
            this.paginationParamsRazorpayPayments.totalPages =
              razorpayPaymentsData.totalPages;
          })
          .catch((e) => {
            this.showConnectionErrorDialog(e);
            this.razorpayPaymentsLoaded = true;
          });

        // Manual Payments
        // client
        //   .getPurchaseTransactions(this.paginationParamsPayments.page)
        //   .then((paymentsData) => {
        //     this.paymentsArr = paymentsData.txns;
        //     this.paymentsLoaded = true;
        //     this.paginationParamsPayments.totalPages = paymentsData.totalPages;
        //   })
        //   .catch((e) => {
        //     this.showConnectionErrorDialog(e);
        //     this.paymentsLoaded = true;
        //   });
        this.paymentsLoaded = true;

        // Player Recharges
        let rechargesData = await client.getResellerPlayerTransactions(
          this.paginationParamsPlayerRecharges.resellerId,
          this.paginationParamsPlayerRecharges.page,
          this.$store.state.account.email,
          this.getDateRangeObj(this.rechargesData)
        );
        this.playerRechargesArr = rechargesData.txns;
        this.playerRechargesLoaded = true;
        this.paginationParamsPlayerRecharges.totalPages =
          rechargesData.totalPages;

        // Player Network
        let playersData = await client.getPlayers(
          "",
          "",
          0,
          this.$store.state.account.email,
          this.paginationParamsPlayerNetwork.page
        );
        this.playerNetworkArr = playersData.playersData;
        this.playerNetworkLoaded = true;
        this.paginationParamsPlayerNetwork.totalPages = playersData.totalPages;

        //Resellers
        let resellersData = await client.getResellerList(
          this.paginationParamsResellers.page,
          this.$store.state.account.email
        );
        this.resellerArr = resellersData.list;
        const that = this;
        await this.resellerArr.forEach(async function (x) {
          x = await that.getUserType(x);
        });
        this.resellersLoaded = true;
        this.paginationParamsResellers.totalPages = resellersData.totalPages;
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchReseller(event) {
      event.preventDefault();
      this.resellerStatus = "";
      this.subresellerParentResellerId = "";
      this.kycButtonText = "";
      this.isProceed = false;
      this.isReseller = false;
      this.isLoading = true;
      this.resellerId = this.resellerId.toLowerCase().trim();
      try {
        const reseller = await client.getUser(this.resellerId);
        if (!reseller) {
          this.showConnectionErrorDialog("Email ID not found.");
          return;
        }

        if (
          reseller.userType === SageConstants.userTypeConstants.RESELLER ||
          reseller.userType === SageConstants.userTypeConstants.SUBRESELLER ||
          reseller.userType === SageConstants.userTypeConstants.RETAILER
        ) {
          this.isReseller = true;
        } else {
          this.showConnectionErrorDialog("Invalid reseller email id.");
          return;
        }

        this.resellerStatus = reseller.status;
        this.name = reseller.name;
        this.phoneNumber = reseller.phoneNumber;
        if (this.resellerStatus === SageConstants.resellerConstants.ACTIVE) {
          this.walletBalance = await client.getMoonfrogCredits(
            this.resellerId,
            this.$store.state.account.email
          );
          const resellerData = await client.getReseller(
            this.resellerId,
            this.$store.state.account.email
          );
          this.resellerCountryCode =
            resellerData && resellerData.countryCode
              ? resellerData.countryCode
              : SageConstants.countryCode.IN;
          this.subresellerParentResellerId =
            reseller.userType === SageConstants.userTypeConstants.SUBRESELLER ||
            reseller.userType === SageConstants.userTypeConstants.RETAILER
              ? resellerData.parentResellerId
              : "";
          switch (resellerData.contractLink) {
            case null:
              this.kycButtonText = SageConstants.signzyConstants.REQUEST_KYC;
              break;
            case SageConstants.signzyConstants.REQUESTED:
              this.kycButtonText = SageConstants.signzyConstants.PENDING_KYC;
              break;
            default:
              this.kycButtonText = SageConstants.signzyConstants.VIEW_KYC;
          }
        } else if (
          this.resellerStatus === SageConstants.resellerConstants.PENDING
        ) {
          if (
            reseller.userType === SageConstants.userTypeConstants.SUBRESELLER ||
            reseller.userType === SageConstants.userTypeConstants.RETAILER
          ) {
            const resellerData = await client.getReseller(
              this.resellerId,
              this.$store.state.account.email
            );
            this.subresellerParentResellerId = resellerData.parentResellerId;
          } else {
            this.subresellerParentResellerId = "";
          }
          // const KYCResponse = await client.getKYCData(
          //   this.resellerId,
          //   this.$store.state.account.email
          // );
          //   var binary_string = window.atob(KYCResponse);
          //   var len = binary_string.length;
          //   var bytes = new Uint8Array(len);
          //   for (var i = 0; i < len; i++) {
          //     bytes[i] = binary_string.charCodeAt(i);
          //   }
          //   const KYCextension = await FileType.fromBuffer(bytes.buffer);
          //   this.KYCimage =
          //     "data:" + KYCextension.mime + ";base64," + KYCResponse;
        }
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    async updateWallet(event) {
      event.preventDefault();
      const amount = parseFloat(this.addMFCredits);
      if (amount <= 0 || !amount || amount > 100000) {
        this.showConnectionErrorDialog(
          "MF Credits to be added should be more than 0 and less than 1 Lakh."
        );
        return;
      }

      let reason = "";
      const that = this;
      this.$swal
        .fire({
          title: "Write the reason for granting MF Credits.",
          text: "Amount: " + amount.toString() + " MF Credits",
          input: "text",
          inputAttributes: {
            maxlength: 255,
            autocapitalize: "off",
            autocorrect: "off",
          },
          inputValidator: (value) => {
            if (!value.trim()) {
              return "The reason can't be empty!";
            }
          },
        })
        .then(async function (result) {
          that.isLoading = true;
          reason = result.value.trim();
          try {
            await client.grantMfcredits(
              that.resellerId,
              amount,
              SageConstants.userTypeConstants.MFOPS,
              reason,
              that.$store.state.account.email
            );
            that.walletBalance = await client.getMoonfrogCredits(
              that.resellerId,
              that.$store.state.account.email
            );
            await that.handlePageChangeMfopsGrants(1);
            that.$swal.fire({
              icon: "success",
              text: "MF Credits successfully added.",
            });
          } catch (e) {
            that.showConnectionErrorDialog(e);
          } finally {
            that.isLoading = false;
          }
        });
    },

    async deactivate() {
      this.resellerStatus = "";
      let reason = "";
      const that = this;
      this.$swal
        .fire({
          title: "Write the reason for deactivation.",
          input: "text",
          showCancelButton: true,
          inputAttributes: {
            maxlength: 255,
            autocapitalize: "off",
            autocorrect: "off",
          },
          inputValidator: (value) => {
            if (!value.trim()) {
              return "The reason can't be empty!";
            }
          },
        })
        .then(async function (result) {
          reason = result.value.trim();
          that.isLoading = true;
          try {
            let changedReseller = await client.updateResellerStatus(
              that.resellerId,
              SageConstants.resellerConstants.DEACTIVATED,
              that.$store.state.account.email,
              reason
            );
            await that.handlePageChangeResellers(1).then(() => {
              that.$swal.fire({
                icon: "success",
                text: changedReseller.userType + " successfully deactivated.",
              });
            });
          } catch (e) {
            that.showConnectionErrorDialog(e);
          } finally {
            that.isLoading = false;
          }
        });
    },

    async activate() {
      this.resellerStatus = "";
      this.isLoading = true;
      try {
        let changedReseller = await client.updateResellerStatus(
          this.resellerId,
          SageConstants.resellerConstants.ACTIVE,
          this.$store.state.account.email,
          "reason"
        );
        await this.handlePageChangeResellers(1).then(() => {
          this.$swal.fire({
            icon: "success",
            text: changedReseller.userType + " successfully activated.",
          });
        });
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    proceed() {
      this.isProceed = true;
      this.resellerStatus = "";
    },

    async reject() {
      this.resellerStatus = "";
      let reason = "";
      const that = this;
      this.$swal
        .fire({
          title: "Write the reason for rejection.",
          input: "text",
          inputAttributes: {
            maxlength: 255,
            autocapitalize: "off",
            autocorrect: "off",
          },
          inputValidator: (value) => {
            if (!value.trim()) {
              return "The reason can't be empty!";
            }
          },
        })
        .then(async function (result) {
          reason = result.value.trim();
          that.isLoading = true;
          try {
            let changedReseller = await client.updateResellerStatus(
              that.resellerId,
              SageConstants.resellerConstants.REJECTED,
              that.$store.state.account.email,
              reason
            );
            await that.handlePageChangeResellers(1).then(() => {
              that.$swal.fire({
                icon: "success",
                text: changedReseller.userType + " successfully rejected.",
              });
            });
          } catch (e) {
            that.showConnectionErrorDialog(e);
          } finally {
            that.isLoading = false;
          }
        });
    },

    async onboard() {
      if (
        !this.gender ||
        !this.dateOfBirth ||
        !this.resellerId ||
        !this.countryCode
      ) {
        this.showConnectionErrorDialog("Please fill all values");
        return;
      }
      this.isProceed = false;
      this.isLoading = true;
      try {
        let changedReseller = await client.updateResellerStatus(
          this.resellerId,
          SageConstants.resellerConstants.ACTIVE,
          this.$store.state.account.email,
          "reason",
          this.gender,
          this.dateOfBirth,
          this.countryCode
        );
        await this.handlePageChangeResellers(1).then(() => {
          this.$swal.fire({
            icon: "success",
            text: changedReseller.userType + " successfully activated.",
          });
        });
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
        this.dateOfBirth = "";
        this.gender = "";
        this.countryCode = "";
      }
    },

    async handlePageChangePayments(value) {
      this.isLoading = true;
      this.paymentsLoaded = false;
      this.paginationParamsPayments.page = value;
      try {
        let paymentsData = await client.getPurchaseTransactions(
          this.paginationParamsPayments.page
        );
        this.paymentsArr = paymentsData.txns;
        this.paymentsLoaded = true;
        this.paginationParamsPayments.totalPages = paymentsData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChangeResellers(value) {
      this.isLoading = true;
      this.resellersLoaded = false;
      this.paginationParamsResellers.page = value;

      try {
        let resellersData = await client.getResellerList(
          this.paginationParamsResellers.page,
          this.$store.state.account.email
        );
        this.resellerArr = resellersData.list;
        const that = this;
        await this.resellerArr.forEach(async function (x) {
          x = await that.getUserType(x);
        });
        this.resellersLoaded = true;
        this.paginationParamsResellers.totalPages = resellersData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChangeRazorpayPayments(value) {
      this.isLoading = true;
      this.razorpayPaymentsLoaded = false;
      this.paginationParamsRazorpayPayments.page = value;
      try {
        let paymentsData =
          await PaymentClient.getResellerCreditPurchaseTransactions(
            "",
            this.paginationParamsRazorpayPayments.page,
            this.$store.state.account.email,
            this.getDateRangeObj(this.paymentDates)
          );
        this.razorpayPaymentsArr = paymentsData.txns;
        this.razorpayPaymentsLoaded = true;
        this.paginationParamsRazorpayPayments.totalPages =
          paymentsData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async searchPaymentTxns() {
      this.isLoading = true;
      this.razorpayPaymentsLoaded = false;
      this.paginationParamsRazorpayPayments.page = 1;
      try {
        let paymentsData =
          await PaymentClient.getResellerCreditPurchaseTransactions(
            "",
            this.paginationParamsPayments.page,
            this.$store.state.account.email,
            this.getDateRangeObj(this.paymentDates)
          );
        this.razorpayPaymentsArr = paymentsData.txns;
        this.razorpayPaymentsLoaded = true;
        this.paginationParamsRazorpayPayments.totalPages =
          paymentsData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChangeMfopsGrants(value) {
      this.isLoading = true;
      this.grantsLoaded = false;
      this.paginationParamsMfopsGrants.page = value;

      try {
        let grantsData = await client.grantHistory(
          this.paginationParamsMfopsGrants.page,
          this.$store.state.account.email,
          SageConstants.userTypeConstants.MFOPS,
          "empty",
          this.getDateRangeObj(this.grantDates)
        );
        this.mfopsGrantsArr = grantsData.txns;
        this.paginationParamsMfopsGrants.totalPages = grantsData.totalPages;
        this.grantsLoaded = true;
        this.mfopsGrantsArr.forEach((item) => {
          item.sender = item.resellerId ? item.resellerId : item.mfopsId;
          item.senderType = item.resellerId ? "RESELLER" : "MFOPS";
        });
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChangePlayerRecharges(queryData) {
      this.isLoading = true;
      this.playerRechargesLoaded = false;
      this.paginationParamsPlayerRecharges.page = queryData.pageNo;
      try {
        let rechargesData = await client.getResellerPlayerTransactions(
          this.paginationParamsPlayerRecharges.resellerId,
          this.paginationParamsPlayerRecharges.page,
          this.$store.state.account.email,
          this.getDateRangeObj(queryData.dates)
        );
        this.playerRechargesArr = rechargesData.txns;
        this.playerRechargesLoaded = true;
        this.paginationParamsPlayerRecharges.totalPages =
          rechargesData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChangePlayerNetwork(queryData) {
      this.isLoading = true;
      this.playerNetworkLoaded = false;
      this.paginationParamsPlayerNetwork.page = queryData.pageNo;
      try {
        let playersData = await client.getPlayers(
          "",
          queryData.resellerId,
          queryData.gameId,
          this.$store.state.account.email,
          this.paginationParamsPlayerNetwork.page
        );
        this.playerNetworkArr = playersData.playersData;
        this.playerNetworkLoaded = true;
        this.paginationParamsPlayerNetwork.totalPages = playersData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async searchGrantTxns() {
      this.grantsLoaded = false;
      this.isLoading = false;
      this.paginationParamsMfopsGrants.page = 1;
      try {
        let grantsData = await client.grantHistory(
          this.paginationParamsMfopsGrants.page,
          this.$store.state.account.email,
          SageConstants.userTypeConstants.MFOPS,
          "empty",
          this.getDateRangeObj(this.grantDates)
        );
        this.mfopsGrantsArr = grantsData.txns;
        this.grantsLoaded = true;
        this.paginationParamsMfopsGrants.totalPages = grantsData.totalPages;
        this.mfopsGrantsArr.forEach((item) => {
          item.sender = item.resellerId ? item.resellerId : item.mfopsId;
          item.senderType = item.resellerId ? "RESELLER" : "MFOPS";
        });
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async searchRechargeTxns(dateRange) {
      this.playerRechargesLoaded = false;
      this.isLoading = false;
      this.paginationParamsPlayerRecharges.page = 1;
      try {
        let rechargesData = await client.getResellerPlayerTransactions(
          this.paginationParamsPlayerRecharges.resellerId,
          this.paginationParamsPlayerRecharges.page,
          this.$store.state.account.email,
          this.getDateRangeObj(dateRange)
        );
        this.playerRechargesArr = rechargesData.txns;
        this.playerRechargesLoaded = true;
        this.paginationParamsPlayerRecharges.totalPages =
          rechargesData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async searchPlayerNetwork(queryData) {
      this.isLoading = true;
      this.playerNetworkLoaded = false;
      this.paginationParamsPlayerNetwork.page = 1;

      try {
        let playersData = await client.getPlayers(
          "",
          queryData.resellerId,
          queryData.gameId,
          this.$store.state.account.email,
          this.paginationParamsPlayerNetwork.page
        );
        this.playerNetworkArr = playersData.playersData;
        this.playerNetworkLoaded = true;
        this.paginationParamsPlayerNetwork.totalPages = playersData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async downloadGrantTxns() {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          "",
          this.$store.state.account.email,
          "grant",
          this.getDateRangeObj(this.grantDates)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async downloadPaymentTxns() {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          "",
          this.$store.state.account.email,
          "payment",
          this.getDateRangeObj(this.paymentDates)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async downloadRechargeTxns(dateRange) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          "",
          this.$store.state.account.email,
          "recharge",
          this.getDateRangeObj(dateRange)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async downloadPlayerNetwork(queryData) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          queryData.resellerId,
          this.$store.state.account.email,
          "playerNetwork",
          {},
          queryData.gameId
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async getUserType(resellerObj) {
      if (
        (resellerObj.userType === SageConstants.userTypeConstants.RESELLER &&
          resellerObj.status === SageConstants.resellerConstants.ACTIVE) ||
        resellerObj.userType === SageConstants.userTypeConstants.SUBRESELLER ||
        resellerObj.userType === SageConstants.userTypeConstants.RETAILER
      ) {
        try {
          const resellerData = await client.getReseller(
            resellerObj.id,
            this.$store.state.account.email
          );
          resellerObj.parentResellerId = resellerData.parentResellerId;
          if (resellerObj.status === SageConstants.resellerConstants.ACTIVE) {
            if (resellerData && resellerData.countryCode) {
              resellerObj.userType += " (" + resellerData.countryCode + ")";
            } else {
              resellerObj.userType += " (IN)";
            }
          }
        } catch (err) {
          this.showConnectionErrorDialog(err);
        }
      } else {
        resellerObj.parentResellerId = "";
      }
      resellerObj.mfOpsIdActDeactReject = resellerObj.mfOpsIdActDeact
        ? resellerObj.mfOpsIdActDeact
        : resellerObj.mfOpsIdOnboardReject;
      return resellerObj;
    },
    onGrantDateSelected() {
      if (!this.grantDates) return;
      this.grantDates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
    },
    onPaymentDateSelected() {
      if (!this.paymentDates) return;
      this.paymentDates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
    },

    getResellerColor(status) {
      if (status === SageConstants.resellerConstants.ACTIVE) return "green";
      else if (status === SageConstants.resellerConstants.DEACTIVATED)
        return "orange";
      else if (status === SageConstants.resellerConstants.PENDING)
        return "brown";
      else return "red";
    },

    getPaymentColor(status) {
      if (status === SageConstants.paymentConstants.PENDING) return "#ffc107";
      else if (
        status === SageConstants.paymentConstants.APPROVED ||
        status === SageConstants.paymentConstants.SUCCESS
      )
        return "green";
      else return "red";
    },

    getPlayerNetworkColor(status) {
      return status ? "green" : "orange";
    },

    getTimeStamp(timestamp) {
      if (!timestamp) {
        return null;
      }
      const epoch = Date.parse(timestamp);
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(epoch).toLocaleString("en-IN", {
        timeZone: tz,
      });
    },

    getRZPPaymentColor(status) {
      if (status === SageConstants.razorpayConstants.AUTHORIZED)
        return "#ffc107";
      else if (status === SageConstants.razorpayConstants.CAPTURED)
        return "green";
      else return "red";
    },

    getRZPStatusCode(status) {
      if (status === SageConstants.razorpayConstants.CAPTURED) return "success";
      else return status;
    },

    getRZPTimeStamp(timestamp) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(timestamp * 1000).toLocaleString("en-IN", {
        timeZone: tz,
      });
    },

    async approvePayment(txn) {
      if (txn.status !== SageConstants.resellerConstants.PENDING) {
        this.showConnectionErrorDialog(
          "Actions are valid only for PENDING transactions."
        );
        return;
      }
      const that = this;
      this.$swal
        .fire({
          title: "Are you sure?",
          icon: "question",
          showCancelButton: true,
        })
        .then(async function (result) {
          if (!result.isConfirmed) {
            return;
          }
          try {
            that.isLoading = true;
            await client.approveMFPayment(
              txn.id,
              that.$store.state.account.email
            );
            await that.handlePageChangePayments(1).then(() => {
              that.$swal.fire({
                icon: "success",
                text: "Payment successfully approved.",
              });
            });
          } catch (e) {
            that.showConnectionErrorDialog(e);
          } finally {
            that.isLoading = false;
          }
        });
    },

    async rejectPayment(txn) {
      if (txn.status !== SageConstants.resellerConstants.PENDING) {
        this.showConnectionErrorDialog(
          "Actions are valid only for PENDING transactions."
        );
        return;
      }
      let reason = "";
      const that = this;
      this.$swal
        .fire({
          title: "Write the reason for rejection.",
          input: "text",
          inputAttributes: {
            maxlength: 255,
            autocapitalize: "off",
            autocorrect: "off",
          },
          inputValidator: (value) => {
            if (!value.trim()) {
              return "The reason can't be empty!";
            }
          },
        })
        .then(async function (result) {
          try {
            reason = result.value.trim();
            that.isLoading = true;
            await client.rejectMFPayment(
              txn.id,
              that.$store.state.account.email,
              reason
            );
            await that.handlePageChangePayments(1).then(() => {
              that.$swal.fire({
                icon: "success",
                text: "Payment successfully rejected.",
              });
            });
          } catch (e) {
            that.showConnectionErrorDialog(e);
          } finally {
            that.isLoading = false;
          }
        });
    },

    // clearPicker() {
    //   console.log(this.dates);
    //   this.dates = [];
    //   this.$refs.menu.save(this.dates);
    // },

    // showKYC() {
    //   // this.$swal.fire({
    //   //   title: "KYC Document",
    //   //   text: "Carefully check the content, and proceed if all OK.",
    //   //   imageUrl: this.KYCimage,
    //   // });
    //   var w = window.open("about:blank");
    //   const that = this;
    //   setTimeout(function () {
    //     const iframe = w.document.body.appendChild(
    //       w.document.createElement("iframe")
    //     );
    //     iframe.src = that.KYCimage;
    //     iframe.style.height = "100%";
    //     iframe.style.width = "100%";
    //   }, 0);
    // },

    async onKycButtonClicked() {
      switch (this.kycButtonText) {
        case SageConstants.signzyConstants.REQUEST_KYC:
          await this.requestKYC();
          break;
        case SageConstants.signzyConstants.VIEW_KYC:
          await this.getKYC();
          break;
      }
      return;
    },

    async requestKYC() {
      this.isLoading = true;
      try {
        if (this.resellerCountryCode !== SageConstants.countryCode.IN) {
          throw new Error(
            "KYC Journey Only Available for Indian Distributors."
          );
        }
        await client.requestKYC(
          this.resellerId,
          this.$store.state.account.email
        );
        this.kycButtonText = SageConstants.signzyConstants.PENDING_KYC;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
        this.$swal.fire({
          icon: "success",
          text: "Signzy Onboarding Email successfully sent to the reseller.",
        });
      }
    },

    async getKYC() {
      this.isLoading = true;
      try {
        var KYCResponse = await client.getKycContract(
          this.resellerId,
          this.$store.state.account.email
        );

        var binary_string = window.atob(KYCResponse);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        const KYCextension = await FileType.fromBuffer(bytes.buffer);
        const KYCimage = "data:" + KYCextension.mime + ";base64," + KYCResponse;
        var w = window.open("about:blank");
        setTimeout(function () {
          const iframe = w.document.body.appendChild(
            w.document.createElement("iframe")
          );
          iframe.src = KYCimage;
          iframe.style.height = "100%";
          iframe.style.width = "100%";
        }, 0);
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchBkashPayment(event) {
      event.preventDefault();
      this.spTransID = this.spTransID.trim();
      try {
        this.isLoading = true;
        let bkashResponse = await PaymentClient.checkBkashPaymentStatus(
          this.spTransID,
          this.$store.state.account.email
        );
        let popupText = bkashResponse.messageText;
        let confirmBtnText = bkashResponse.isResolve
          ? "Proceed with Reconciliation"
          : "OK";
        let mfCredits = SageUtils.getMFCreditsFromAmount(
          bkashResponse.amount,
          "BD",
          this.$store.state.creditExchangeRate["BD"]
        );
        let status = bkashResponse.isCharged
          ? SageConstants.razorpayConstants.CAPTURED
          : "FAILED";
        this.$swal
          .fire({
            icon: "info",
            text: popupText,
            confirmButtonText: confirmBtnText,
            showCancelButton: bkashResponse.isResolve,
          })
          .then((result) => {
            if (result.isConfirmed && bkashResponse.isResolve) {
              this.$swal.fire({
                icon: "warning",
                confirmButtonText: "Send MF Credits",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !this.$swal.isLoading(),
                title: "Transaction Details",
                html:
                  "<br><b>spTransID: </b>" +
                  bkashResponse.spTransID +
                  "<br><b>Price: </b>" +
                  bkashResponse.amount +
                  "<br><b>MF Credits: </b>" +
                  mfCredits +
                  "<br><b>Status: </b>" +
                  status +
                  "<br>",
                input: "text",
                inputAttributes: {
                  maxlength: 255,
                  autocapitalize: "off",
                  autocorrect: "off",
                },
                inputValidator: (value) => {
                  if (!value.trim()) {
                    return "The email id can't be empty!";
                  }
                },
                inputPlaceholder: "Enter the email id of the reseller.",
                preConfirm: async (resellerEmailId) => {
                  this.$swal.getCancelButton().setAttribute("hidden", true);
                  return PaymentClient.sendBkashResolve(
                    bkashResponse.spTransID,
                    bkashResponse.amount,
                    mfCredits,
                    status,
                    resellerEmailId,
                    this.$store.state.account.email
                  )
                    .then((data) => {
                      if (data) {
                        this.$swal
                          .fire({
                            icon: "success",
                            text: "Grant Successful",
                            confirmButtonText: "OK",
                          })
                          .then(() => {
                            this.$router.go();
                          });
                      } else {
                        this.$swal.fire({
                          icon: "warning",
                          text: "Grant Unsuccessful",
                          confirmButtonText: "OK",
                        });
                      }
                    })
                    .catch((error) => {
                      this.showConnectionErrorDialog(error);
                    });
                },
              });
            }
          });
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    signOut() {
      localStorage.removeItem("access_token");
      this.$store.commit("resetAccount");
      this.$router.push("/");
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
    getDateRangeObj(dateRange) {
      if (!dateRange) return {};
      if (dateRange.length === 1)
        return { from: dateRange[0], to: dateRange[0] };
      else if (dateRange.length === 2)
        return { from: dateRange[0], to: dateRange[1] };
      else return {};
    },
  },
};
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}
.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}
.container {
  background: #ffd89b;
  background: linear-gradient(to right, #ffd89b, #19547b);
  min-height: 100vh;
  max-width: none;
}
.form-control::placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}
.icon {
  position: absolute;
  left: 2rem;
  color: #495057;
  font-size: 1rem;
  vertical-align: -webkit-baseline-middle;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  padding: 50px;
}
.modal .body {
  background-color: #fff;
}
.modal .close {
  right: 5px;
  position: relative;
  font-size: 30px;
  text-align: right;
  top: 30px;
  cursor: pointer;
}
.add-mf-credit-btn {
  border: solid;
  border-left: none;
  border-width: 1px;
  border-color: rgb(206, 212, 218);
  border-radius: 4px;
  height: 38px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#deactivate-btn {
  background-color: #dc3545;
  border-color: #dc3545;
}
@media (min-width: 1264px) {
  .col-lg-8 {
    flex: 0 0 80%;
    max-width: 100%;
  }
}
</style> 
