<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          <div style="padding: 10px" v-bind:class="titleClass">
            {{ tableTitle }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="playerNetworkResellerId"
              label="Reseller Email"
              v-if="isMfopsRequestor"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-select
              v-model="selectedGameId"
              :items="gamesArr"
              item-text="name"
              item-value="gameId"
              label="Select Game"
              clearable
              @change="search()"
            ></v-select>
            <v-btn
              class="
                mx-1
                subtitle-1
                white--text
                font-weight-regular
                text-capitalize
              "
              color="teal darken-4"
              medium
              v-if="isMfopsRequestor"
              @click="search()"
              ><v-icon dark> fa fa-search</v-icon>
            </v-btn>
            <v-btn
              class="
                mx-1
                subtitle-1
                white--text
                font-weight-regular
                text-capitalize
              "
              @click="download()"
              :disabled="playerNetworkArr.length === 0"
              color="light-blue darken-4"
              ><v-icon dark> mdi-cloud-download</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-layout v-resize="onResize" column>
          <v-data-table
            :headers="playerNetworkHeaders"
            :items="playerNetworkArr"
            disable-pagination
            :hide-default-footer="true"
            class="elevation-1"
            :loading="isLoading"
          >
            <!-- eslint-disable -->
            <!-- get readable timestamp -->
            <template v-slot:item.installTimestamp="{ item }">
              {{ getTimeStamp(item.installTimestamp) }}
            </template>
            <!-- color status chip -->
            <template v-slot:item.status="{ item }">
              <v-chip :color="getPlayerNetworkColor(item.status)" dark>
                {{ getPlayerNetworkStatusText(item.status) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-layout>
        <v-pagination
          v-model="pageNo"
          :length="totalPagesCount"
          total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="pageChanged"
        ></v-pagination>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "../utils/SageUtils";
import * as client from "@/client/webclient";
export default {
  name: "PlayerNetworkTable",
  props: {
    players: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Player Network",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    isMfopsRequestor: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    players: function (newVal, oldVal) {
      this.playerNetworkArr = newVal;
    },
    totalPages: function (newVal, oldVal) {
      this.totalPagesCount = newVal;
    },
    page: function (newVal, oldVal) {
      this.pageNo = newVal;
    },
  },
  data() {
    return {
      playerNetworkArr: [],
      isMobile: false,
      playerNetworkHeaders: this.getHeader(),
      pageNo: 1,
      totalPagesCount: 0,
      tableTitle: "",
      playerNetworkResellerId: "",
      selectedGameId: 0,
      gamesArr: [],
      //   dates: [
      //     SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
      //     SageUtils.getDateFromToday(0),
      //   ],
      //   menu: false,
    };
  },
  computed: {
    // dateRangeText() {
    //   return this.dates.join("~");
    // },
    titleClass() {
      if (this.isMobile) return "col";
      return "row";
    },
  },
  mounted() {
    this.playerNetworkArr = this.players;
    this.pageNo = this.page;
    this.totalPagesCount = this.totalPages;
    this.tableTitle = this.title;
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.gamesArr = await client.getGames(this.$store.state.account.email);
      } catch (e) {
        this.showConnectionErrorDialog(e);
      }
    },
    // download() {
    //   this.$emit("download", this.dates);
    // },
    search() {
      this.playerNetworkResellerId = this.playerNetworkResellerId.trim();
      this.$emit("search", {
        resellerId: this.playerNetworkResellerId,
        gameId: this.selectedGameId,
      });
    },
    download() {
      this.playerNetworkResellerId = this.playerNetworkResellerId.trim();
      this.$emit("download", {
        resellerId: this.playerNetworkResellerId,
        gameId: this.selectedGameId,
      });
    },
    pageChanged(value) {
      this.$emit("pageChanged", { pageNo: value, dates: this.dates });
    },
    getTimeStamp(timestamp) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(timestamp).toLocaleString("en-IN", {
        timeZone: tz,
      });
    },
    getPlayerNetworkColor(status) {
      return status
        ? SageConstants.colors.ACTIVE
        : SageConstants.colors.PENDING;
    },
    getPlayerNetworkStatusText(status) {
      return status
        ? SageConstants.resellerConstants.ACTIVE
        : SageConstants.resellerConstants.PENDING;
    },
    getHeader() {
      return [
        {
          text: "Install Date/Time",
          align: "start",
          value: "installTimestamp",
        },
        { text: "Reseller Email", value: "resellerId" },
        { text: "Game Name", value: "gameName" },
        { text: "Player ID", value: "playerId" },
        { text: "Total Purchase", value: "amount" },
        { text: "Status", value: "status" },
      ];
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    // onDateSelected() {
    //   if (!this.dates) return;
    //   this.dates.sort(function (a, b) {
    //     return new Date(a) - new Date(b);
    //   });
    //   if (this.dates.length < 2) return;
    //   const dateStart = new Date(this.dates[0]);
    //   const dateEnd = new Date(this.dates[1]);
    //   const diffTime = Math.abs(dateEnd - dateStart);
    //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //   if (diffDays > SageConstants.MAX_DATE_RANGE) {
    //     this.dates = [
    //       SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
    //       SageUtils.getDateFromToday(0),
    //     ];
    //     this.$swal.fire({
    //       title: "Error",
    //       icon: "error",
    //       text: `Please select the date range within ${SageConstants.MAX_DATE_RANGE} days`,
    //     });
    //   }
    // },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>
